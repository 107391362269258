import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../layouts";
import SEO from "../components/seo";
import Link from "../elements/link";
import Header from "../modules/header";
import linkHelper from "../helpers/link";
import LoaderRing from "../elements/loader/ring";
import { submitEntry } from "../helpers/photo-comp";

export const query = graphql`
  query PhotoCompFormPageTemplateQuery($id: String!) {
    page: sanityPagePhotoComp(id: { eq: $id }) {
      title
      excerpt
      formIntro
      error
      success
      _rawBody(resolveReferences: { maxDepth: 8 })
      breadcrumbs {
        title
        slug
      }
      termsLink {
        type
        page {
          ... on SanityPage {
            title
            slug {
              current
            }
            path
          }
          ... on SanityPagePhotoComp {
            title
            slug {
              current
            }
            path
          }
          ... on SanityPageNews {
            title
            slug {
              current
            }
            path
          }
          ... on SanityPageAlumni {
            title
            slug {
              current
            }
            path
          }
          ... on SanityPagePolicy {
            title
            slug {
              current
            }
            path
          }
          ... on SanityPageContact {
            title
            slug {
              current
            }
            path
          }
          ... on SanityPageEvents {
            title
            slug {
              current
            }
            path
          }
        }
        file {
          title
          file {
            asset {
              url
              size
              extension
            }
          }
        }
        external {
          url
          title
        }
      }
      seo {
        title
        description
        keywords
        image {
          asset {
            _id
            altText
            metadata {
              lqip
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
          hotspot {
            height
            width
            x
            y
          }
        }
      }
    }
  }
`;

const CalendarCompForm = ({ data }) => {
  const page = data.page;

  const [image, setImage] = React.useState({});

  // Form data
  const [caption, setCaption] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [member, setMember] = React.useState(false);
  const [address, setAddress] = React.useState("");
  const [state, setState] = React.useState("");
  const [postcode, setPostcode] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(null);
  const [fileUploadError, setFileUploadError] = React.useState("");

  const imageInput = React.createRef();

  const onImageChange = e => {
    if (e.target.files.length) {
      setFileUploadError("");
      const file = e.target.files[0];
      const fileSize = file.size;
      if (fileSize > 4194304) {
        e.target.value = null;
        setFileUploadError(
          `File must be less than 4MB in size - please resize the image and try again.`
        );
        return;
      }

      setImage({
        filename: e.target.files[0].name,
        file: e.target.files[0],
        src: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const onFormSubmit = async e => {
    try {
      e.preventDefault();
      setSubmitting(true);

      const response = await submitEntry({
        image,
        caption,
        firstName,
        lastName,
        email,
        member,
        address,
        state,
        postcode,
        phone,
      });
      setSubmitSuccess(response !== false);
    } catch (error) {
      setSubmitSuccess(false);
    } finally {
      setSubmitting(false);
    }
  };

  const link = {
    ...page.termsLink,
    text: "terms and conditions",
  };

  return (
    <Layout className="p-calendar-comp">
      <SEO
        title={page.title}
        excerpt={page.excerpt}
        image={page.image}
        seo={page.seo}
        taxonomy={page.taxonomy}
      />
      <Header
        title={page.title}
        breadcrumbs={page.breadcrumbs}
        backgroundColour="#f6f3ea"
      />
      <div className="container">
        <p>{page.formIntro}</p>
        <section className="p-calendar-comp-form">
          <form action="" onSubmit={onFormSubmit}>
            <div className="field">
              <div className="file has-name">
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    accept="image/*"
                    name="image"
                    onChange={onImageChange}
                    ref={imageInput}
                    required
                  />
                  <span className="file-cta">
                    <span className="file-label">Upload your image</span>
                  </span>
                  <span className="file-name">
                    {image?.filename ? image.filename : "No file uploaded"}
                  </span>
                  {image?.src && (
                    <img src={image.src} alt="" className="file-preview" />
                  )}
                </label>
              </div>
              {fileUploadError ? (
                <p>
                  <strong>{fileUploadError}</strong>
                </p>
              ) : null}
            </div>
            <div className="field">
              <label className="label" htmlFor="caption">
                Image Caption
              </label>
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder="Include a caption for your photo here"
                  rows="3"
                  name="caption"
                  value={caption}
                  onChange={e => {
                    e.preventDefault();
                    setCaption(e.target.value);
                  }}
                  required
                ></textarea>
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="firstName">
                First Name
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="firstName"
                  placeholder="First name"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="lastName">
                Last Name
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="email">
                Email
              </label>
              <div className="control">
                <input
                  className="input"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="email">
                Address
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="email">
                State
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="state"
                  placeholder="State"
                  value={state}
                  onChange={e => setState(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="email">
                Postcode
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="postcode"
                  placeholder="Postcode"
                  value={postcode}
                  onChange={e => setPostcode(e.target.value)}
                  required
                  minLength={4}
                  maxLength={4}
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="email">
                Phone number
              </label>
              <div className="control">
                <input
                  className="input"
                  type="tel"
                  name="phone"
                  placeholder="Phone number"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="member"
                    value={member}
                    onChange={e => setMember(!member)}
                  />
                  I'm a current GrainGrowers member
                </label>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label className="checkbox">
                  <input type="checkbox" name="consent" required />I have read
                  and agree with the competition{" "}
                  {link && <Link {...linkHelper(link)} includeIcon={false} />}.
                </label>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  className="e-button -primary"
                  disabled={
                    submitting ||
                    submitSuccess !== null ||
                    fileUploadError.length > 0
                  }
                >
                  {submitting ? <LoaderRing fill="#000" /> : "Submit"}
                </button>
              </div>
            </div>
            {submitSuccess && (
              <div className="p-calendar-comp-form-success">{page.success}</div>
            )}

            {submitSuccess === false && (
              <div className="p-calendar-comp-form-error">{page.error}</div>
            )}
          </form>
        </section>
      </div>
    </Layout>
  );
};

CalendarCompForm.propTypes = {
  data: PropTypes.object,
};

export default CalendarCompForm;
